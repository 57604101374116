import Vue from "vue";
import App from "@/App.vue";
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import store from "@/store";
import titleMixin from "@/mixins/titleMixin";

Vue.config.productionTip = false;
Vue.mixin(titleMixin);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
