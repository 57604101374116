<template>
  <div id="nav">
    <v-toolbar color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-tab to="/"
        ><h3 class="cyan--text text--lighten-4">Inspiring Awe</h3></v-tab
      >

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-navigation-drawer
      color="blue lighten-2"
      v-model="drawer"
      absolute
      temporary
    >
      <v-list-item class="primary darken-4" to="/">
        <v-list-item-avatar>
          <v-icon color="white darken-4" size="40" class="no-italics">
            tz-bi-blackatmosphere
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            ><h3 class="white--text">Black Atmosphere</h3></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          link
        >
          <v-list-item-icon>
            <v-icon size="34" class="no-italics">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content class="ml-0">
            <v-list-item-title
              ><h3>{{ item.title }}</h3></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      drawer: null,
      items: [
        {
          title: "About",
          icon: "tz-sdi-world-wide",
          link: "/about",
        },
        {
          title: "What We Do",
          icon: "tz-sdi-server-security",
          link: "/services",
        },
        {
          title: "Get in Touch",
          icon: "tz-sdi-participant",
          link: "/contact",
        },
      ],
    };
  },
};
</script>
