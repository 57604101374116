<template>
  <main>
    <div align="center">
      <v-layout justify-center>
        <v-row dense>
          <v-col>
            <v-card color="teal lighten-1" height="100%">
              <v-icon color="teal darken-4" size="200" class="no-italics">
                tz-bi-blackatmosphere
              </v-icon>
              <v-layout class="ma-2" justify-center wrap column :row="false">
                <h1 class="teal--text text--darken-4">{{ about.name }}</h1>
                <h3 class="teal--text text--darken-4">
                  {{ about.serviceDescription }}
                </h3>
                <p class="teal--text text--darken-4">{{ about.description }}</p>
              </v-layout>

              <v-card-actions>
                <div class="join-us">
                  <v-btn
                    to="/contact"
                    class="teal darken-4 white--text mb-3"
                    rounded
                    large
                  >
                    Join Us &raquo;
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card></v-col
          ></v-row
        ></v-layout
      >
      <v-layout justify-center>
        <v-row dense>
          <v-col v-for="(item, i) in coreServices" :key="i">
            <v-card color="cyan darken-1" height="100%">
              <div class="d-flex flex-no-wrap">
                <div>
                  <v-card-title class="headline mb-2"
                    ><h4 class="cyan--text text--darken-4">
                      {{ item.title }}
                    </h4></v-card-title
                  >

                  <v-card-subtitle class="text-left"
                    ><p class="cyan--text text--darken-4">
                      {{ item.description }}
                    </p>
                  </v-card-subtitle>

                  <v-card-actions>
                    <v-btn
                      :to="item.link"
                      class="cyan darken-4 white--text ml-2 mb-3"
                      rounded
                      small
                    >
                      Read More &raquo;
                    </v-btn>
                  </v-card-actions>
                </div>

                <v-avatar class="ma-2" size="125" tile>
                  <v-icon class="no-italics" color="cyan darken-4" size="125">{{
                    item.icon
                  }}</v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </div>
  </main>
</template>

<script>
export default {
  title: "BA - Welcome",
  computed: {
    about() {
      return this.$store.getters.about;
    },
    coreServices() {
      return this.$store.getters.coreServices;
    },
  },
};
</script>
