import Vue from "vue";
import Vuex from "vuex";
import companyDetails from "./modules/company-details";
import services from "./modules/services";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { companyDetails, services },
});
