import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const theme = {
  primary: {
    base: "#1f6f8b",
  },
  secondary: {
    base: "#99a8b2",
  },
  background: "#99a8b2",
};

export default new Vuetify({
  theme: {
    themes: {
      light: theme,
    },
    dark: false,
  },
});
