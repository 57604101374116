const state = {
  coreServices: [
    {
      title: "Branding",
      icon: "tz-bi-jmarket",
      description: `What are your values and how will you stay true to them? Why 
      you? Why this? Why now? Why for them? Why there? Why that way and not this way?`,
      link: "/services#brand-strategy",
    },
    {
      title: "Hosting",
      icon: "tz-bi-kopanong",
      description: `We host secured, fast, and easy to use cloud applications across major
    cloud computing providers. Providing the best experience for mobile and desktop clients.`,
      link: "/services#cloud-services",
    },
    {
      title: "Distribution",
      icon: "tz-bi-damng",
      description: `We want to see our partners and clients get paid in the 
    most secure and reliable way possible with the current cloud services and
    distribution channels in our repertoire.`,
      link: "/services#payment-integration",
    },
  ],
  subServices: {
    brandingStrategies: [
      {
        title: "Market Research",
        icon: "tz-bsi-earnings-report",
        description: `If you choose the wrong market, your brand will be 
        guaranteed to fail. This first stage is the single most important 
        part of any brand system, and the one that most people get wrong.`,
        link: "/services#market-research",
      },
      {
        title: "Keyword Research",
        icon: "tz-bsi-progress-report",
        description: `Picking the right market is just the first step. How to 
        use it effectively to uncover the right kinds of keywords is a whole 
        other question. If you are guessing at any point, you aren’t doing it 
        properly.`,
        link: "/services#keyword-research",
      },
      {
        title: "Web Setup",
        icon: "tz-dai-distribution-b",
        description: `There are millions of tools, plugins and themes nowadays. 
        With so many to choose from, it’s difficult to get/choose the right one. 
        Slow, clunky and out of date technology will kill your rankings.`,
        link: "/services#website",
      },
      {
        title: "Design",
        icon: "tz-esi-compass",
        description: `Getting hung up on your fonts? Not keen on your color scheme? 
        What should be a quick decision can slow you down. The worst part? 
        Designing a great looking site is easier than you think.`,
        link: "/services#design",
      },
      {
        title: "Content Creation",
        icon: "tz-rci-wordpress",
        description: `It’s not as simple as just writing about a topic. Your content 
        needs to be appealing to both your readers AND Search Engines. Most people waste a 
        huge amount of time writing content destined to fail.
        
        Your texts and images are crucial parts of your products and services. You 
        shouldn’t keep them constrained to one platform or presentation.
        
        We keep your content flexible and limber. Ready to outrun any new challenge.`,
        link: "/services#content-creation",
      },
      {
        title: "Link Building",
        icon: "tz-dai-link",
        description: `Even if you’ve got this far, your site won’t rank without links. 
        It’s no secret, link building is hard. Scaling true white hat link building is 
        even more difficult. There are severe penalties for doing it wrong.`,
        link: "/services#link-building",
      },
      {
        title: "Hiring & Outsourcing",
        icon: "tz-owi-screen-sharing",
        description: `You don’t want to be doing all the work yourself forever. Hiring the 
        right people online is tricky. Outsourcing, if done incorrectly, can be a costly 
        mistake for any brand.`,
        link: "/services#hiring",
      },
      {
        title: "Monetisation",
        icon: "tz-dai-coins",
        description: `This is why you’re doing this in the first place, right? To make money. 
        Many brands neglect proven monetization strategies which are easy to implement.`,
        link: "/services#monetisation",
      },
      {
        title: "Analytics & Tracking",
        icon: "tz-owi-progress",
        description: `You need to set up the right tracking tools to keep tabs on the growth 
        of your business. Plus, you want to be able to identify areas that need some serious 
        attention. Can you say “technical nightmare?”`,
        link: "/services#analytics",
      },
    ],
    hostingServices: {
      providers: [
        {
          title: "Amazon Web Services",
          icon: "tz-spi-aws",
          description: `Offers reliable, scalable, and inexpensive cloud computing services.`,
          link: "/services#aws",
        },
        {
          title: "DiaMatrix",
          icon: "tz-spi-domains-coza",
          description: `South African ISP that offers domain registrations, hosting, connectivity, 
          bulk emailing and other related ISP services.`,
          link: "/services#domains",
        },
        {
          title: "Heroku",
          icon: "tz-spi-heroku",
          description: `Cloud platform as a service supporting several programming languages. One of 
          the first cloud platforms.`,
          link: "/services#heroku",
        },
        {
          title: "Google Cloud",
          icon: "tz-spi-google-cloud",
          description: `A suite of cloud computing services that runs on the same infrastructure 
          that Google uses internally for its end-user products, such as Google Search, Gmail, file 
          storage, and YouTube`,
          link: "/services#google",
        },
        {
          title: "Microsoft Azure",
          icon: "tz-spi-azure",
          description: `Azure is a cloud computing service created by Microsoft for building, testing, 
          deploying, and managing applications and services through Microsoft-managed data centers.`,
          link: "/services#azure",
        },
      ],
      solutions: [
        {
          title: "Auth Services",
          icon: "tz-sdi-server-security",
          description: `We have our own security system that is easily pluggable into other applications 
          to protect software related resources from unauthorised access.`,
          link: "/services#auth",
        },
        {
          title: "Automation",
          icon: "tz-sdi-config",
          description: `We create processes that require little to no assistance from human interaction.`,
          link: "/services#automation",
        },
        {
          title: "Storage Engines",
          icon: "tz-sdi-data-state",
          description: `We use AWS S3, Google Drive, Azure Storage, and pCloud Drive integrations to 
          store and manage files.`,
          link: "/services#storage",
        },
        {
          title: "Database Engines",
          icon: "tz-sdi-datastore",
          description: `We use MongoDB, MariaDB, PostgresDB, RethinkDB, CouchDB, and RedisDB to store 
          and manage collections of data.`,
          link: "/services#database",
        },
        {
          title: "Computing Engines",
          icon: "tz-sdi-cloud-compute",
          description: `We use AWS EC2, Google Compute Engine, and Azure Virtual Machines to run 
          programmable instructions.`,
          link: "/services#computing",
        },
        {
          title: "API Gateway",
          icon: "tz-sdi-api",
          description: `We develop computing interfaces that provides interaction between multiple 
          software systems.`,
          link: "/services#api",
        },
        {
          title: "Business Logic",
          icon: "tz-sdi-data-analysis",
          description: `We develop applications that will run your business rules that determines 
          how data is created, stored, and changed.`,
          link: "/services#logic",
        },
        {
          title: "Streaming & Messaging",
          icon: "tz-sdi-binary-sync",
          description: `We develop applications to transport your data through network layers, and 
          provide notification services through streaming packets of data.`,
          link: "/services#messaging",
        },
        {
          title: "Presentation",
          icon: "tz-sdi-data-insights",
          description: `We develop interfaces for web, mobile, and tablet devices for interacting 
          with the participants.`,
          link: "/services#presentation",
        },
        {
          title: "Continuous Integration & Delivery",
          icon: "tz-sdi-cloud-push",
          description: `CI/CD bridges the gaps between development and operation activities and teams 
          by enforcing automation in building, testing and deployment of applications.`,
          link: "/services#cicd",
        },
      ],
    },
    distributionServices: [
      {
        title: "Paperback Publishing",
        icon: "tz-dai-book-publishing",
        description: `We publish softcover books that that can be sold to individuals and/or distributed
        to physical stores.`,
        link: "/services#publishing",
      },
      {
        title: "Kindle Direct Publishing",
        icon: "tz-dai-kindle",
        description: `We distribute ebooks to Amazon Kindle.`,
        link: "/services#kdp",
      },
      {
        title: "Amazon Music",
        icon: "tz-dai-amazon-music",
        description: `We sell and distribute music to Amazon Music platform.`,
        link: "/services#amazon-music",
      },
      {
        title: "Apple Music/iTunes",
        icon: "tz-dai-itunes",
        description: `We sell and distribute music to iTunes and Apple Music.`,
        link: "/services#apple-music",
      },
      {
        title: "Deezer",
        icon: "tz-dai-deezer",
        description: `We sell and distribute music to Deezer, a French online music streaming service.`,
        link: "/services#deezer",
      },
      {
        title: "YouTube Music",
        icon: "tz-dai-youtube-music",
        description: `We sell and distribute music to YouTube Music.`,
        link: "/services#youtube-music",
      },
      {
        title: "Shazam",
        icon: "tz-dai-shazam",
        description: `We distribute music to Shazam so they can be identified easily.`,
        link: "/services#shazam",
      },
      {
        title: "Payfast",
        icon: "tz-spi-payfast",
        description: `An online payment processing solution for individuals, NPOs and businesses of all sizes 
        in South Africa.`,
        link: "/services#payfast",
      },
      {
        title: "Paypal",
        icon: "tz-spi-paypal",
        description: `The safer and easier way to pay and get paid online. The service allows anyone to pay in 
        any way they prefer, including through credit cards, bank accounts, PayPal Smart Connect or account 
        balances, without sharing financial information.`,
        link: "/services#paypal",
      },
      {
        title: "Peach Payments",
        icon: "tz-spi-peach-payments",
        description: `Enables businesses to easily accept payments on their websites and mobile apps.`,
        link: "/services#peach-payments",
      },
      {
        title: "PayGate",
        icon: "tz-spi-paygate",
        description: `Enables businesses to accept payments online with all currencies and payment methods, 
        including all major cards, mobile money, and e-wallets.`,
        link: "/services#paygate",
      },
      {
        title: "OZOW",
        icon: "tz-spi-ozow",
        description: `Easy, premium, automated EFT solution that can be done in seconds while staying Ozow secure. `,
        link: "/services#ozow",
      },
    ],
  },
};

const getters = {
  coreServices: (state) => state.coreServices,
  subServices: (state) => state.subServices,
  brandingStrategies: (state) => state.subServices.brandingStrategies,
  hostingServices: (state) => state.subServices.hostingServices,
  distributionServices: (state) => state.subServices.distributionServices,
};
export default {
  state,
  getters,
};
