<template>
  <v-app
    id="app"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <navigation />
    <v-container fluid>
      <router-view />
    </v-container>
    <Footer />
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },
  data: () => ({
    bgColor: "primary",
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style src="@/assets/style.css"></style>
