<template>
  <v-footer dark padless class="mt-auto">
    <v-card flat tile class="primary white--text text-center" width="100%">
      <v-card-text>
        <v-btn
          v-for="socialNetwork in socials"
          :key="socialNetwork.icon"
          class="mx-4 white--text"
          icon
          :href="socialNetwork.link"
        >
          <v-icon size="24px">
            {{ socialNetwork.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        Digital Assets Marketplace
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        &copy; 2017 - {{ new Date().getFullYear() }} |
        <strong>Black Atmosphere (PTY) LTD</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    socials: [
      {
        icon: "mdi-linkedin",
        link: "https://za.linkedin.com/company/black-atmosphere",
      },
      {
        icon: "mdi-facebook",
        link: "https://facebook.com/blackatmospher5",
      },
      {
        icon: "mdi-twitter",
        link: "https://twitter.com/blackatmospher5",
      },
      {
        icon: "mdi-instagram",
        link: "https://instagram.com/blackatmospher5",
      },
    ],
  }),
};
</script>
