const state = {
  about: {
    name: "Black Atmosphere (PTY) LTD",
    serviceDescription: "Digital Assets Marketplace",
    description: `We offer platforms for individuals and businesses to sell, buy and manage digital assets and other 
    intangible assets such as software, agreements, licenses, patents, etc.`,
    timeline: [
      {
        year: "2021",
        slogan: "Inverting Power",
        description: `Creating beautiful computing solutions to software tools and products, to help people organize, 
        optimize, and actualize their business, finance, content, productivity, and personal life.`,
      },
      {
        year: "2020",
        slogan: "Digitize. Automate. Monetize",
        description: `We researched and developed autonomous protocols to improve our machine learning algorithms. 
        The intention was to build robust digital networks using token economics, game-theory, and gamification 
        to engage participants in solving complex problems and challenges in our community while rewarding 
        them for their contributions.`,
      },
      {
        year: "2019",
        slogan: "Digital Assets Management",
        description: `We developed tools and softwares to help improve our business and make data driven decisions. The 
        vision is bringing machine learning and creative content management together within Black Atmosphere.`,
      },
      {
        year: "2018",
        slogan: "Shine Your Imagination",
        description: `The results we saw from putting out our own content and monetizing it resulted with creating 
        a business model on how we aggrigate, license and monetize other people's content so they can shine 
        their imagination. We collaborated with four freelance bloggers and distributed music for eleven artists 
        to the streaming services.`,
      },
      {
        year: "2017",
        slogan: "Inspiring Awe",
        description: `We started with the intention of having a pervading tone and mood on the internet by “Inspiring 
        Awe” and focusing on our community of creative individuals. We created, curated, and distributed our content 
        i.e. Books, Blogs, Music, Graphic Designs and monetized it online. We monetized our blog posts using Google Adsense, 
        we shared affliate links to Amazon and iTunes products. We distributed our music to Apple Music, Tidal, Spotify, 
        Google Play, Shazam, and other streaming services.`,
      },
    ],
  },
};

const getters = {
  about: (state) => state.about,
};

export default {
  state,
  getters,
};
